import React, { useState } from 'react';
import Image from 'next/legacy/image';

import { IHeader2Fields } from '@/types/contentful';

import { useEscapeEvent } from '../../../../../hooks/useEscapeEvent';
import { getImageDataWithHttps } from '../../../../../utils/contentful';

import { NavigationLink } from './DesktopNavLink';
import CtaWidget from '../../../../CtaWidget';
import Link from '../../../../Link';
import { ProfileDropdownMenu } from '../../../ProfileDropdownMenu';

import {
  DesktopNavbarContainer,
  DesktopNavbarColumn,
  LogoWrapper,
  LinksContainer
} from '../DesktopNavbar.styles';

import defaultFullLogo from '@/images/gl-logo-full-large.svg';
import defaultAbbreviatedLogo from '@/images/gl-logo-medium.svg';

interface Props extends Partial<IHeader2Fields> {
  isScrolled: boolean;
  isSimplified: boolean;
}

const defaultAbbreviatedLogoData = {
  ...defaultAbbreviatedLogo,
  width: 29,
  height: 24,
  alt: 'Greenlight logo'
};

const defaultFullLogoData = {
  ...defaultFullLogo,
  alt: 'Greenlight logo'
};

export function DesktopNavbar({
  isScrolled,
  isSimplified,
  navItems,
  desktopCta,
  abbreviatedLogoDesktop,
  fullLogoDesktop,
  linkToHome,
  showAuthenticatedUser,
  roundedCornersVariation
}: Props) {
  const [selected, setSelected] = useState<string | undefined>(undefined);

  const abbreviatedLogoData = abbreviatedLogoDesktop
    ? getImageDataWithHttps(abbreviatedLogoDesktop)
    : defaultAbbreviatedLogoData;

  const fullLogoData = fullLogoDesktop
    ? getImageDataWithHttps(fullLogoDesktop)
    : defaultFullLogoData;

  useEscapeEvent(() => setSelected(undefined));

  return (
    <DesktopNavbarContainer
      roundedCornersVariation={roundedCornersVariation}
      isScrolled={isScrolled}
      data-testid="desktop-nav"
    >
      <DesktopNavbarColumn>
        <Link
          data-testid="desktop-logo-link"
          href={linkToHome ? linkToHome : '/'}
        >
          {isScrolled && !showAuthenticatedUser ? (
            <LogoWrapper>
              <Image
                src={abbreviatedLogoData.src}
                alt={abbreviatedLogoData.alt}
                height={abbreviatedLogoData.height}
                width={abbreviatedLogoData.width}
              />
            </LogoWrapper>
          ) : (
            <Image
              src={fullLogoData.src}
              alt={fullLogoData.alt}
              height={fullLogoData.height}
              width={fullLogoData.width}
            />
          )}
        </Link>

        <LinksContainer
          data-testid="desktop-navlinks"
          isSimplified={isSimplified}
        >
          {navItems &&
            navItems.map(({ fields }: any, idx: number) => (
              <NavigationLink
                {...fields}
                key={idx}
                selected={selected === (fields?.name || fields?.title)}
                setSelected={setSelected}
              />
            ))}
        </LinksContainer>

        {desktopCta && !showAuthenticatedUser && (
          <CtaWidget
            {...desktopCta?.fields}
            id="desktop-nav-cta"
            sysId={desktopCta?.sys?.contentType?.sys?.id}
            module="Navigation"
          />
        )}

        {showAuthenticatedUser && <ProfileDropdownMenu />}
      </DesktopNavbarColumn>
    </DesktopNavbarContainer>
  );
}
