import Image from 'next/legacy/image';

import { mediaQueries } from '../../../../styles/media-queries';
import { pxToRem } from '../../../../styles/utils';
import styled from '../../../../theme/styled-with-theme';

import Link from '../../../Link';

export const MobileNavbarWrapper = styled('div')`
  position: relative;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pxToRem(12)} ${pxToRem(20)};
  height: ${pxToRem(56)};

  @media (min-width: 1080px) {
    display: none;
  }
`;

export const HamburgerContainer = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: ${pxToRem(10)};
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
`;

export const Hamburger = styled('div')`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const LogoWrapper = styled('div')`
  height: ${pxToRem(24)};
`;

export const Modal = styled('div')`
  display: flex;
  position: fixed;
  inset: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
`;

export const ModalContainer = styled('div')`
  padding: 20px 0;
  flex: 1;
  overflow-y: scroll;
`;

export const ModalBody = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  outline: 0;
`;

export const NavLinkContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CaretWrapper = styled('div')`
  padding: 0 ${pxToRem(14)};
`;

export const Caret = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'rotated'
})<{ rotated: boolean }>`
  transform: ${({ rotated }) =>
    rotated ? 'rotateZ(-90deg)' : 'rotateZ(0deg)'};
  transition: transform 0.3s cubic-bezier(0, 0.7, 0.3, 1);
`;

export const CtaButtonContainer = styled('div')`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.ml} ${theme.spacing['2xl']}`};
  background-color: ${({ theme }) => theme.color.mobileMenuGetStartedBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NavLink = styled(Link)`
  font-size: ${pxToRem(20)};
  font-weight: ${({ theme }) => theme.fontWeight['500']};
  color: #1d252c;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SublinkContainer = styled('div')<{
  expanded: boolean | undefined;
}>`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  flex-direction: column;
  flex-wrap: wrap;
`;

export const SublinkList = styled('ul')`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing[0]}`};
  column-count: 2;
`;

export const SublinkItem = styled('li')`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const Sublink = styled(Link)`
  font-size: ${pxToRem(14)};
  font-weight: ${({ theme }) => theme.fontWeight['400']};
  color: #1d252c;
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacing.m};
`;

export const StickyContainer = styled('div')`
  position: fixed;
  bottom: -1px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 3;
  
  padding: ${pxToRem(18)} 0;

  ${mediaQueries.laptop} {
    display: none;
  }
`;
