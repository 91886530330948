import * as React from 'react';
import styled from '@emotion/styled';

type ArrowNorthEastProps = {
  fill?: string;
  width?: number;
  height?: number;
  isParentHovered?: boolean;
};

const StyledSvg = styled.svg<{ $isParentHovered?: boolean }>`
  path {
    fill: white;
  }

  &:hover path,
  ${({ $isParentHovered }) => $isParentHovered && `path { fill: black; }`}
`;

export function ArrowNorthEast({
  width = 27,
  height = 27,
  isParentHovered = false,
  ...props
}: ArrowNorthEastProps) {
  return (
    <StyledSvg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      $isParentHovered={isParentHovered}
      {...props}
    >
      <path d="M7.49996 3.33333L4.99996 5.83333L12.9166 5.83333L4.16663 14.8204L6.01361 16.6667L15 7.91666L15 15.8333L17.5 13.3333L17.5 3.33333L7.49996 3.33333Z" />
    </StyledSvg>
  );
}
