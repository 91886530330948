import Link from '../Link';
import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';
import { pxToRem } from '../../styles/utils';

import { Theme } from '../../theme/types';
import {
  useFontWeightProp,
  FontWeightProp
} from '../../theme/tokens/font-weight';
import { ColorToken } from '../../theme/tokens/color';

import { Text } from '../Text';
import { Box } from '../Box';
import { GlImage } from '../Image';

import blackberryBullet from '@/images/blackberry-bullet.svg';
import whiteBullet from '@/images/white-bullet.svg';
import greenBullet from '@/images/green-bullet.svg';

export const handleColorType = (theme: Theme, color: ColorToken) => {
  if (theme.color[color]) {
    return theme.color[color];
  }
  return theme.color.defaultText;
};

export const InlineAnchor = styled(Link)<{
  colour?: any;
  fontWeight?: FontWeightProp;
}>`
  color: ${({ theme, colour }) =>
    handleColorType(
      theme,
      typeof colour === 'string' ? (colour as ColorToken) : 'defaultText'
    )};
  ${useFontWeightProp}
  text-decoration: underline;
  transition: 0.2s color ease;

  &:hover {
    color: ${({ theme }) => theme.color.darkButton};
  }

  ${mediaQueries.tablet} {
    color: ${({ theme, colour }) =>
      colour && handleColorType(theme, colour?.tablet || colour)};
  }

  ${mediaQueries.desktop} {
    color: ${({ theme, colour }) =>
      colour && handleColorType(theme, colour?.desktop || colour)};

    &:hover {
      color: ${({ theme, colour }) => {
        const hasDefaultText =
          colour === 'defaultText' || colour?.desktop === 'defaultText';
        return handleColorType(
          theme,
          hasDefaultText ? 'darkButton' : 'inlineAnchorHover'
        );
      }};
    }
  }
`;

export const ParagraphWithLineBreaks = styled(Text)<{
  removeBottomMarginSpacing?: boolean;
}>`
  white-space: pre-wrap;
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: ${({ removeBottomMarginSpacing, theme }) =>
      removeBottomMarginSpacing ? '0' : theme.spacing.ml};
  }
`;

export const UnorderedList = styled('ul')<{
  bulletColor?: 'white' | 'green' | 'black';
}>`
  padding-left: 0;
  list-style-image: url(${greenBullet.src});

  ${mediaQueries.min} {
    padding-left: 1.5rem;
  }

  ${mediaQueries.tablet} {
    padding-left: 0;
    list-style-image: ${({ bulletColor }) => {
      switch (bulletColor) {
        case 'black':
          return `url(${blackberryBullet.src})`;
        case 'white':
          return `url(${whiteBullet.src})`;
        case 'green':
        default:
          return `url(${greenBullet.src})`;
      }
    }};
`;

export const OrderedList = styled('ol')`
  list-style-type: auto;
  padding-inline-start: 40px;
`;

export const ListItem = styled('li')`
  padding-left: ${({ theme }) => theme.spacing.s};
  margin-left: -${({ theme }) => theme.spacing.s};
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

const ONE_COLUMN_WIDTH = 100 / 12;
export const BlogsBlockquoteContainer = styled(Box)`
  margin: ${({ theme }) => theme.spacing.ml} -${ONE_COLUMN_WIDTH}%;
  padding: ${({ theme }) => theme.spacing.l} ${ONE_COLUMN_WIDTH}%;
  text-align: center;

  ${mediaQueries.tablet} {
    margin: ${({ theme }) => theme.spacing.l} -${ONE_COLUMN_WIDTH}%;
    padding: ${({ theme }) => theme.spacing.xl} ${ONE_COLUMN_WIDTH}%;
    text-align: left;
  }
`;

export const LearningBlockquoteContainer = styled(Box)`
  margin: ${({ theme }) => theme.spacing.ml} -${ONE_COLUMN_WIDTH}%;
  padding: ${({ theme }) => theme.spacing.ml};
  text-align: left;
  border-radius: ${pxToRem(24)} !important;

  ${mediaQueries.tablet} {
    margin: ${({ theme }) => theme.spacing.l} -${ONE_COLUMN_WIDTH}%;
    margin-top: ${pxToRem(-20)};
  }

  ${mediaQueries.desktop} {
    &:first-child {
      margin-top: ${pxToRem(-135)};
    }

    margin-top: ${pxToRem(20)};
    margin-left: -${pxToRem(32)};
    margin-right: -${pxToRem(32)};
  }

  ${mediaQueries.max} {
    width: ${pxToRem(850)};
  }
`;

export const StyledImage = styled(GlImage)`
  margin: ${({ theme }) => theme.spacing.ml} -${ONE_COLUMN_WIDTH}%;
  border-radius: ${({ theme }) => theme.borderRadius.blockquote_0};

  ${mediaQueries.tablet} {
    margin: ${({ theme }) => theme.spacing.l} -${ONE_COLUMN_WIDTH}%;
    border-radius: ${({ theme }) => theme.borderRadius.blockquote_tablet};
  }
`;

const FOUR_COLUMN_WIDTH = 100 / 8;
export const PageBlockPositioner = styled('div')`
  margin: ${({ theme }) => theme.spacing.ml} -${ONE_COLUMN_WIDTH}%;

  ${mediaQueries.tablet} {
    margin: ${({ theme }) => theme.spacing.l} -${FOUR_COLUMN_WIDTH}%;
  }
`;
