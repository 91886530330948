import { mediaQueries } from '../../styles/media-queries';
import { pxToRem } from '../../styles/utils';
import styled from '../../theme/styled-with-theme';

import { Flexbox } from '../Flexbox';
import { Button } from '../Button';
import Link from '../Link';

export const WEB_BANNER_HEIGHT = 48;
const DARK_BACKGROUND_COLOR = '#353a41';
const CLOSE_BUTTON_SIZE = 32;

export const WebBannerWrapper = styled(Flexbox)`
  width: 100vw;
  margin: auto;
  height: ${pxToRem(WEB_BANNER_HEIGHT)};

`;

export const WebBannerCloseButton = styled(Button)`
  max-width: ${pxToRem(CLOSE_BUTTON_SIZE)} !important;
  min-width: ${pxToRem(CLOSE_BUTTON_SIZE)} !important;
  width: ${pxToRem(CLOSE_BUTTON_SIZE)} !important;
  height: ${pxToRem(CLOSE_BUTTON_SIZE)} !important;
  background-color: ${DARK_BACKGROUND_COLOR} !important;
  border-radius: ${({ theme }) => theme.borderRadius.input_button} !important;
  padding: 0px !important;
  line-height: 0px !important;
`;

export const InlineAnchor = styled(Link)`
  color: #00d9b4;
  font-weight: ${({ theme }) => theme.fontWeight.p};
  text-decoration: underline;
  transition: 0.2s color ease;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.color.darkButton};
  }
`;

export const CTALinkButtonRight = styled(Link)`
  display: none;

  ${mediaQueries.desktop} {
    text-decoration: none;
    background-color: #343B41;
    border-radius: 0px 0px ${pxToRem(16)} 0px;
    height: 100%;
    min-width: ${pxToRem(168)};
    font-size: ${({ theme }) => theme.fontSize.sm};
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    gap: 0px;
    color: white;

    &:hover {
      background-color: #00C9A7;
      color: black;

      svg {
        fill: white;
      }
    }
  }

  svg {
    transform: scale(0.75);
  }
`;

export const CTALinkButtonOnly = styled(Link)`
  text-decoration: none;
  width: ${pxToRem(56)};
  background-color: ${DARK_BACKGROUND_COLOR};
  border-radius: 0px 0px ${pxToRem(24)} 0px;
  height: 100%;
  font-size: ${pxToRem(16)};
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;

  ${mediaQueries[0]} {
    width: ${pxToRem(48)};
  }

  ${mediaQueries.min} {
    width: ${pxToRem(56)};
  }

  ${mediaQueries.desktop} {
    display: none;
  }

  svg {
    transform: scale(0.75);
  }
`;
