import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { IHeader2Fields } from '@/types/contentful';

import { usePageDataContext } from '../../context/PageData';
import { useCookie } from '../../hooks/useCookie';
import { useScrollPosition } from '../../hooks/useScrollPosition';

import { Banners } from '../Body/Banners';
import { DesktopNavbar } from './Viewports/DesktopNav/DesktopNavComponents';
import { NavItem as MobileNav } from './Viewports/MobileNav';

export function Navbar(props: IHeader2Fields) {
  const {
    page: { hideHeader, hideBanner, isSimplified, pageWebBanner } = {},
    cookies: {
      gl_web_banner: glWebBanner,
      gl_is_registered_user: registeredUser
    } = {}
  } = usePageDataContext();

  const [isScrolled, setIsScrolled] = useState(false);
  // Default banner display status from server cookie and page prop
  const [bannerVisible, updateBanner] = useState(
    glWebBanner === 'show' && registeredUser !== 'true' && hideBanner !== true
  );

  // Use client cookie to manage for banner status
  const [wbCookie, updateCookie] = useCookie('gl_web_banner');
  const [ruCookie] = useCookie('gl_is_registered_user');

  useEffect(() => {
    // Update banner when cookie changes
    updateBanner(
      wbCookie === 'show' && ruCookie !== 'true' && hideBanner !== true
    );
  }, [wbCookie, ruCookie, hideBanner]);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y > 0) setIsScrolled(true);
      else if (currPos.y <= 0) setIsScrolled(false);
    },
    [isScrolled],
    undefined,
    true,
    100
  );

  return (
    <>
      {bannerVisible && (
        <Banners
          toggleBanner={updateCookie}
          roundedCornersVariation={props.roundedCornersVariation}
          pageWebBanner={pageWebBanner}
        />
      )}

      {!hideHeader && (
        <div
          className={cx('w-full', {
            'mx-auto my-0 max-w-screen-2xl': props.roundedCornersVariation
          })}
        >
          <MobileNav
            {...props}
            isSimplified={!!isSimplified}
            isScrolled={isScrolled}
          />
          <DesktopNavbar
            {...props}
            isSimplified={!!isSimplified}
            isScrolled={isScrolled}
          />
        </div>
      )}
    </>
  );
}
