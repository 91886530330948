import React from 'react';
import cx from 'classnames';

import { ILink } from '../../../../../../types/contentful';

import { NavItem } from '../../../shared';

import {
  NavLinkContainer,
  NavLink,
  CaretWrapper,
  Caret,
  SublinkContainer,
  Sublink,
  SublinkList,
  SublinkItem
} from '../MobileNav.styles';
import upBlackArrow from '@/images/arrow-right-black-2.svg';

interface Props extends NavItem {
  titleExpanded: string;
  setTitleExpanded: (title: string | undefined) => void;
}
export function ModalNavCategory({
  name,
  title,
  url,
  links,
  tabletLinks,
  mobileLinks,
  titleExpanded,
  setTitleExpanded
}: Props) {
  const expanded = name === titleExpanded;
  const isMeganav = tabletLinks && mobileLinks;
  const ifLinks = links || tabletLinks || mobileLinks;

  const toggleCategoryDisplay = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (titleExpanded === name) {
      return setTitleExpanded(undefined);
    }
    setTitleExpanded(name);
  };

  console.log({ mobileLinks });

  return (
    <div className="group mx-5 my-6 border-b-[2px] border-b-[#F1F1F1] first-of-type:mt-0 last-of-type:border-b-0">
      <NavLinkContainer className="mb-6">
        <NavLink
          aria-controls={`nav-expanded-content-${name}`}
          aria-expanded={expanded}
          href={ifLinks ? '#0' : (url as string)}
          onClick={toggleCategoryDisplay}
        >
          {name || title}
          {ifLinks && (
            <CaretWrapper>
              <Caret
                alt="Expand navigation list"
                src={upBlackArrow}
                rotated={expanded}
              />
            </CaretWrapper>
          )}
        </NavLink>
      </NavLinkContainer>
      <SublinkContainer id={`nav-expanded-content-${name}`} expanded={expanded}>
        {isMeganav ? (
          <>
            <ul
              data-testid="meganav-links"
              className={cx(
                'm-0 w-full list-none py-4 tiny:hidden tablet:inline-block',
                {
                  'tablet:columns-1': tabletLinks
                    ? tabletLinks?.length < 5
                    : false,
                  'tablet:columns-2': tabletLinks
                    ? tabletLinks?.length >= 5 && tabletLinks?.length < 8
                    : false,
                  'tablet:columns-4': tabletLinks
                    ? tabletLinks?.length > 8
                    : false
                }
              )}
            >
              {tabletLinks &&
                tabletLinks.map(
                  (
                    { fields: { title, url, subcategoryLabel } }: any,
                    idx: number
                  ) => (
                    <li className="mb-3" key={title ?? idx}>
                      {subcategoryLabel && (
                        <p className="mb-3 text-base font-medium">
                          {tabletLinks[idx - 1]?.fields?.subcategoryLabel !==
                          subcategoryLabel
                            ? subcategoryLabel
                            : null}
                        </p>
                      )}
                      {url !== '#' ? (
                        <Sublink href={url}>{title}</Sublink>
                      ) : (
                        <div className="tablet:mb-36"></div>
                      )}
                    </li>
                  )
                )}
            </ul>
            <ul
              className={cx(
                'm-0 mb-[13px] inline-block w-full list-none group-first-of-type:-mb-[7px] tiny:inline-block tiny:columns-2 tablet:hidden',
                {
                  'tiny:columns-1': mobileLinks
                    ? mobileLinks?.length < 5
                    : false
                }
              )}
            >
              {mobileLinks &&
                mobileLinks.map(
                  (
                    { fields: { title, url, subcategoryLabel } }: any,
                    idx: number
                  ) => {
                    const nextLinkHasDifferentCategory =
                      mobileLinks[idx + 1]?.fields?.subcategoryLabel !==
                      subcategoryLabel;
                    const previousLinkHasDifferentCategory =
                      mobileLinks[idx - 1]?.fields?.subcategoryLabel !==
                      subcategoryLabel;
                    return (
                      <li
                        className={
                          nextLinkHasDifferentCategory ? 'mb-6' : 'mb-2'
                        }
                        key={title ?? idx}
                        data-testid={`mobile-link-${title ?? idx}`}
                      >
                        {previousLinkHasDifferentCategory && (
                          <p className="mb-2 text-base font-medium">
                            {subcategoryLabel}
                          </p>
                        )}
                        {url !== '#' ? (
                          <Sublink href={url}>{title}</Sublink>
                        ) : (
                          <div className="tiny:mb-16"></div>
                        )}
                      </li>
                    );
                  }
                )}
            </ul>
          </>
        ) : (
          <SublinkList>
            {links &&
              links.map(({ fields }: ILink, idx: number) => (
                <SublinkItem key={fields?.title ?? idx} data-testid="nav-link">
                  <Sublink href={fields?.url}>{fields?.title}</Sublink>
                </SublinkItem>
              ))}
          </SublinkList>
        )}
      </SublinkContainer>
    </div>
  );
}
