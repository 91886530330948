import React from 'react';

import { IVariation } from '../../../types/contentful';

import { usePageDataContext } from '../../context/PageData';

import { VariationContainer } from '../VariationContainer';
import { WebBanner } from '../WebBanner/index';

interface BannerProps {
  toggleBanner: Function;
  roundedCornersVariation?: boolean;
  pageWebBanner?: any;
}

export function Banners({
  toggleBanner,
  roundedCornersVariation,
  pageWebBanner
}: BannerProps) {
  const { config: { webBanner: globalWebBanner } = {} } = usePageDataContext();

  const webBanner = pageWebBanner ?? globalWebBanner;
  const { sys, fields }: any = webBanner ?? {};

  switch (sys?.contentType?.sys?.id) {
    case 'variationContainer':
      // Do not lazy load
      fields.variations!.forEach((variation: IVariation) => {
        if (variation!.fields?.pageBlock) {
          variation!.fields.pageBlock!.fields = {
            ...variation!.fields.pageBlock!.fields,
            toggleBanner,
            roundedCornersVariation
          } as any;
        }
      });
      return <VariationContainer {...fields} sysId={sys?.id} />;

    case 'webBanner':
      return (
        <WebBanner
          {...fields}
          toggleBanner={toggleBanner}
          roundedCornersVariation={roundedCornersVariation}
        />
      );

    default:
      return null;
  }
}
