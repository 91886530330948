import React, { MouseEvent, useEffect, useRef } from 'react';
import Image from 'next/legacy/image';
import startCase from 'lodash/startCase';

import location from '@/images/location-icon-green.svg';

interface ProfileWidgetProps {
  firstName: string;
  thumbnail?: string;
  state?: string;
  onClickHandler: (event: MouseEvent) => void;
  dropdownHidden: boolean;
}

export function ProfileWidget({
  firstName,
  thumbnail,
  state,
  dropdownHidden,
  onClickHandler
}: ProfileWidgetProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (dropdownHidden) {
      // we need to force the focus removal from the button, otherwise it will
      // display a purple outline
      buttonRef.current?.blur();
    }
  }, [dropdownHidden]);

  const truncate = (val: string) =>
    val.length > 20 ? `${val.substring(0, 20)}...` : val;

  return (
    <div className="flex items-center" data-testid="profile-widget">
      {state && (
        <span className="mr-3 hidden items-center rounded-[40px] bg-[#7AE8B626] px-2 py-1 font-medium leading-6 tablet:flex">
          <Image alt="location" src={location} />
          <span className="ml-2">{startCase(state.toLowerCase())}</span>
        </span>
      )}
      <button
        className="flex items-center"
        onClick={onClickHandler}
        ref={buttonRef}
      >
        <span className="mr-3 inline-block font-medium leading-4">
          {truncate(firstName)}
        </span>

        {thumbnail ? (
          <Image
            alt="user thumbnail"
            src={thumbnail}
            width={32}
            height={32}
            className="bg-zinc-100 rounded-full"
            data-testid="user-thumbnail-image"
          />
        ) : (
          <span
            className="bg-zinc-100 inline-block h-8 w-8 rounded-full font-semibold leading-8"
            data-testid="user-thumbnail-initial"
          >
            {firstName && firstName[0]}
          </span>
        )}
      </button>
    </div>
  );
}
