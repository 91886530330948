import React, { useEffect, useMemo } from 'react';
import Head from 'next/head';
import Script from 'next/script';

import {
  IBlogPostFields,
  IDynamicTextFields,
  IPageFields,
  ISeoMetadata
} from '@/types/contentful';

import { usePageDataContext } from '../../context/PageData';
import { usePublicRuntimeConfig } from '../../hooks/usePublicRuntimeConfig';

import FAQRichResults from './FAQRichResults';
import { DynamicSchemaJson } from '../BlogPost/DynamicSchemaJson';
import { DynamicBreadcrumbJson } from '../BlogPost/DynamicBreadcrumbJson';

import glIcon from '@/images/gl-icon.png';
import { addAdditionalDataToDynamicText } from '../../utils/addAdditionalDataToDynamicText';
import { useRouter } from 'next/router';

type PageFields = Partial<IPageFields> & {
  heroImage?: IBlogPostFields['heroImage'];
  publishDate?: IBlogPostFields['publishDate'];
};

interface MetadataProps extends PageFields {
  pathPrefix?: string;
  seoMetadata: ISeoMetadata;
}

export default function Metadata({
  seoMetadata,
  slug,
  schema,
  heroImage,
  publishDate,
  queryString,
  pathPrefix
}: MetadataProps) {
  const { siteMetadata, nodeEnv } = usePublicRuntimeConfig();
  const router = useRouter();

  useEffect(() => {
    if (!queryString) return;

    const searchParams = new URLSearchParams(queryString);

    const needsUpdate = Array.from(searchParams.entries()).some(
      ([key, value]) => router.query[key] !== value
    );

    if (needsUpdate) {
      // Merge into a single object in one step
      const mergedQuery = {
        ...router.query,
        ...Object.fromEntries(searchParams)
      };
      router.replace(
        { pathname: router.pathname, query: mergedQuery },
        undefined,
        {
          shallow: true
        }
      );
    }
  }, [queryString, router]);

  const {
    config: { seoMetadata: globalSeoMetadata } = {},
    advocateUserData,
    cookies
  } = usePageDataContext();

  const {
    seoTitle,
    seoMetaDescription,
    seoImage,
    isNoIndexed,
    canonicalPage,
    seoChildImage
  } = seoMetadata?.fields ?? {};

  const title = seoTitle ?? globalSeoMetadata?.fields.seoTitle ?? '';
  const description =
    seoMetaDescription ?? globalSeoMetadata?.fields?.seoMetaDescription ?? '';
  const image = heroImage ? `https:${heroImage?.fields?.file?.url}` : '';
  const datePublished = publishDate
    ? new Date(publishDate).toISOString().split('T')[0]
    : '';

  const url = useMemo(() => {
    let prefix = pathPrefix;
    let path = slug === 'homepage' ? '' : `/${slug}`;

    const isArticle =
      canonicalPage?.sys?.contentType?.sys?.id === 'learningCenterArticle';

    if (isArticle && canonicalPage?.fields?.slug) {
      const article: any = canonicalPage?.fields ?? {};

      prefix = `/learning-center/${article?.category?.fields?.slug}`;
      path = `/${article?.slug}`;
    } else if (!isArticle && canonicalPage?.fields?.slug) {
      path = `/${canonicalPage?.fields?.slug}`;
    }

    return `https://greenlight.com${prefix}${path}`;
  }, [canonicalPage, pathPrefix, slug]);

  const seoImageUrl = useMemo(() => {
    let imageUrl: string | undefined;

    if (advocateUserData?.isTeen) {
      imageUrl =
        seoChildImage?.fields?.file?.url || seoImage?.fields?.file?.url;
    }

    return (
      imageUrl ??
      seoImage?.fields?.file?.url ??
      globalSeoMetadata?.fields?.seoImage?.fields?.file?.url ??
      siteMetadata?.seoImageUrl
    );
  }, [
    advocateUserData,
    seoChildImage,
    seoImage,
    globalSeoMetadata,
    siteMetadata
  ]);

  const finalTitle = useMemo(() => {
    const { text, channels, keyGeneratedText } =
      seoMetadata?.fields?.dynamicContent?.fields ?? ({} as IDynamicTextFields);

    if (channels && channels.includes(cookies?.utm_source as string)) {
      return (
        addAdditionalDataToDynamicText(
          text,
          keyGeneratedText,
          advocateUserData,
          title
        ) ?? title
      );
    }

    return title;
  }, [seoMetadata]);

  return (
    <>
      <Head>
        <title>{finalTitle}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no"
        />
        <meta
          name="facebook-domain-verification"
          content="a9ko4grvdv4htmv12dsq1r8614p46q"
        />
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />
        <link rel="icon" href={glIcon.src} />

        {(isNoIndexed || nodeEnv !== 'production') && (
          <meta name="robots" content="noindex" />
        )}
        <meta property="og:title" content={finalTitle} />
        <meta property="og:type" content="website" />
        {/* Image used here is the SEO Image in the SEO Metadata on Homepage */}
        <meta property="og:image" content={seoImageUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="twitter:title" content={finalTitle} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@greenlightcard" />
        <meta name="twitter:image" content={seoImageUrl} />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:description" content={description} />
        <meta
          name="google-site-verification"
          content="tRSY9AVtYMJtn1RC2sV52cdh4vXb7kdRpSO6vsumTWM"
        />
        <meta property="fb:app_id" content="1754995511484842" />
        <meta
          name="p:domain_verify"
          content="89139fcf35813cd089059de5e94a5ae2"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      {slug === 'faq' && (
        <Script
          id="faq"
          type="application/ld+json"
          strategy="afterInteractive"
        >{`${FAQRichResults}`}</Script>
      )}
      {schema && (
        <Script
          id="schema"
          type="application/ld+json"
          strategy="afterInteractive"
        >
          {schema?.fields?.schemaJson}
        </Script>
      )}
      {pathPrefix === '/blog' && (
        <>
          <Script
            id="blog-schema"
            type="application/ld+json"
            strategy="afterInteractive"
          >
            {JSON.stringify(
              DynamicSchemaJson(title, url, description, image, datePublished),
              null,
              '\t'
            )}
          </Script>
          <Script
            id="blog-breadcrumb"
            type="application/ld+json"
            strategy="afterInteractive"
          >
            {JSON.stringify(DynamicBreadcrumbJson(title, url), null, '\t')}
          </Script>
        </>
      )}
    </>
  );
}
