import React from 'react';

import { IFooter20Fields } from '../../../../../types/contentful';

import { NavList } from './NavList';

export function Navigation({ footerLinks }: IFooter20Fields) {
  return (
    <div
      className="flex flex-wrap justify-between gap-y-5 tablet:gap-y-6"
      data-testid="footer-nav-links"
    >
      {(footerLinks || []).map(({ fields: { name, links } }: any) => (
        <div
          className="flex w-[calc(50%-10px)] flex-col desktop:mb-0 desktop:w-3/12"
          id="nav-list-column"
          key={name}
        >
          <h3 className="mb-4 text-xl font-medium leading-[120%] text-white tablet:text-[24px]">
            {name}
          </h3>
          <NavList links={links} />
        </div>
      ))}
    </div>
  );
}
