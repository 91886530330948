import React from 'react';

import CtaWidget from '../../../../CtaWidget';
import { ModalNavCategory } from './MobileNavCategory';
import { MobileNavbar } from './MobileNavbar';

import {
  Modal,
  ModalContainer,
  ModalBody,
  CtaButtonContainer
} from '../MobileNav.styles';

import { MobileNavProps } from '../index';

interface Props extends MobileNavProps {
  isMenuOpen: boolean;
  toggleModal: () => void;
}

export function MobileModal({ navItems, modalMobileCta, ...props }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const categoryExpanded = navItems?.find(
    (item: any) => item.fields.isExpanded === true
  ) as any;
  const [titleExpanded, setTitleExpanded] = React.useState<string | undefined>(
    categoryExpanded?.fields?.name
  );

  return (
    <Modal aria-label="Navigation menu" aria-modal="true">
      <ModalBody>
        {/* Reuses the navbar for logo and btn consistency */}
        <MobileNavbar
          {...props}
          isScrolled={false}
          scrolledMobileCta={undefined}
        />

        <ModalContainer data-testid="mobile-modal">
          {navItems &&
            navItems.map(({ fields }: any, idx: number) => (
              <ModalNavCategory
                {...fields}
                setTitleExpanded={setTitleExpanded}
                titleExpanded={titleExpanded}
                key={idx}
              />
            ))}
        </ModalContainer>

        {modalMobileCta && (
          <CtaButtonContainer>
            <CtaWidget
              {...modalMobileCta?.fields}
              id="mobile-modal-nav-cta"
              sysId={modalMobileCta?.sys?.contentType?.sys?.id}
              module="Navigation"
              errorPosition="top"
            />
          </CtaButtonContainer>
        )}
      </ModalBody>
    </Modal>
  );
}
